import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const DigitalTransformation = () => (
  <Layout>
    <SEO title="Modern Web Application Development Stacks" description="Helping businesses add value, simplify and optimize operations, develop new business models and evolve existing ones through customer focused digital transformation."/>
    <div style={{left: '-80px', overflow: 'hidden'}} className={`absolute hidden md:block`}>
        <svg width="195" height="87" viewBox="0 0 195 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M195 84.1704C195 82.928 194.08 81.8776 192.849 81.7138L-5.1951 55.3731C-6.68047 55.1755 -8 56.3312 -8 57.8296V57.8296C-8 59.072 -7.08008 60.1224 -5.84856 60.2862L192.195 86.6269C193.68 86.8245 195 85.6688 195 84.1704V84.1704Z" fill="#F3F2F6"/>
            <path d="M135 29.1704C135 27.928 134.08 26.8776 132.849 26.7138L-65.1951 0.373066C-66.6805 0.175504 -68 1.33116 -68 2.82961V2.82961C-68 4.07198 -67.0801 5.12235 -65.8486 5.28615L132.195 31.6269C133.68 31.8245 135 30.6688 135 29.1704V29.1704Z" fill="#F3F2F6"/>
        </svg>
    </div>
    <div className={`absolute right-0 hidden md:block`}>
      <svg width="167" height="84" viewBox="0 0 167 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 81.1704C0 79.928 0.919917 78.8776 2.15144 78.7138L200.195 52.3731C201.68 52.1755 203 53.3312 203 54.8296C203 56.072 202.08 57.1224 200.849 57.2862L2.8049 83.6269C1.31953 83.8245 0 82.6688 0 81.1704Z" fill="#F3F2F6"/>
        <path d="M60 29.1704C60 27.928 60.9199 26.8776 62.1514 26.7138L260.195 0.373066C261.68 0.175504 263 1.33116 263 2.82961C263 4.07198 262.08 5.12235 260.849 5.28615L62.8049 31.6269C61.3195 31.8245 60 30.6688 60 29.1704Z" fill="#F3F2F6"/>
      </svg>
    </div>
    <div style={{maxWidth: '531px'}} className={'mx-auto mb-12 mt-8 lg:mb-12'}>
        <p className={`uppercase font-bold text-center`} style={{color: '#d2cfdd'}}>A Customer Focused Journey</p>
        <h1 className={`text-3xl text-center md:text-4xl font-bold text-purple-800 leading-10 mb-2 md:mb-4`}>Simplify. Adapt. <br/>Add value.</h1>
    </div>
    <div className={`mx-auto max-w-md flex flex-col items-center mb-12`}>
        <p className={`text-sm md:text-base text-center font-bold text-purple-800`}>Your partner for meaningful change</p>
        <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
        <p className={`text-center text-purple-800 mb-2`}>Nothing is more representative of the business world today then the fast pace of digital transformation across industries. <strong>You can start today and we will be there <br/> with you along the way.</strong></p>
    </div>
    <div className={`relative h-full mb-20`}>
      <div style={{minHeight: '500px', transform: `skewY(-5deg)`}} className={`w-full absolute bg-purple-800`}></div>
      <div style={{minHeight: '500px', transform: `skewY(5deg)`, boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}} className={`w-full relative bg-white`}>
        <div style={{transform: `skewY(-5deg)`}} className={`flex flex-col lg:flex-row items-center lg:items-start justify-around  py-20`}>
          <div className={`absolute`} style={{top: '-40px', right: '-80px', overflow: 'hidden', transform: `skewY(3deg)`}}>
            <svg width="195" height="84" viewBox="0 0 195 84" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 81.1704C0 79.928 0.919917 78.8776 2.15144 78.7138L200.195 52.3731C201.68 52.1755 203 53.3312 203 54.8296C203 56.072 202.08 57.1224 200.849 57.2862L2.8049 83.6269C1.31953 83.8245 0 82.6688 0 81.1704Z" fill="#F16571"/>
              <path d="M60 29.1704C60 27.928 60.9199 26.8776 62.1514 26.7138L260.195 0.373066C261.68 0.175504 263 1.33116 263 2.82961C263 4.07198 262.08 5.12235 260.849 5.28615L62.8049 31.6269C61.3195 31.8245 60 30.6688 60 29.1704Z" fill="white"/>
            </svg>
          </div>
          <div style={{left: '-80px', top: '-50px', overflow: 'hidden'}} className={`absolute hidden md:block`}>
        <svg width="195" height="87" viewBox="0 0 195 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M195 84.1704C195 82.928 194.08 81.8776 192.849 81.7138L-5.1951 55.3731C-6.68047 55.1755 -8 56.3312 -8 57.8296V57.8296C-8 59.072 -7.08008 60.1224 -5.84856 60.2862L192.195 86.6269C193.68 86.8245 195 85.6688 195 84.1704V84.1704Z" fill="#F3F2F6"/>
            <path d="M135 29.1704C135 27.928 134.08 26.8776 132.849 26.7138L-65.1951 0.373066C-66.6805 0.175504 -68 1.33116 -68 2.82961V2.82961C-68 4.07198 -67.0801 5.12235 -65.8486 5.28615L132.195 31.6269C133.68 31.8245 135 30.6688 135 29.1704V29.1704Z" fill="#F3F2F6"/>
        </svg>
    </div>          
          <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
            <div className={`mb-4`}>
              <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="#3E2B7D"/>
                <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="#3E2B7D"/>
              </svg>
            </div>
            <h2 className={`text-lg text-purple-800 font-bold text-center tracking-normal`}>A customer focused journey</h2>
            <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
            <p className={`text-purple-800 text-center`}>Customer focus is the be-all and end-all of digital transformation. It would be impossible to leverage the process for meaningful organizational change otherwise. As we start working together our discovery process is focused around your customer experience.</p>
          </div>

          <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
            <div className={`mb-4`}>
              <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="#3E2B7D"/>
                <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="#3E2B7D"/>
              </svg>
            </div>
            <h2 className={`text-lg text-purple-800 font-bold text-center tracking-normal`}>Simplify and optimize</h2>
            <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
            <p className={`text-purple-800 text-center`}>Optimizing resources and transforming established ways of working to simplify operations and optimize costs. From inventory and logistics to customer service and collections, digital transformation helps businesses meet the challenges of today and prepare for those of tomorrow.</p>
          </div>

          <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
            <div className={`mb-4`}>
              <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="#3E2B7D"/>
                <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="#3E2B7D"/>
              </svg>
            </div>
            <h2 className={`text-lg text-purple-800 font-bold text-center tracking-normal`}>Expand and add value</h2>
            <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
            <p className={`text-purple-800 text-center`}>Developing new business models and evolving existing ones. Augmenting customer experiences through interactive technologies. Gaining a deeper understanding of your data to aid decision making and product development.</p>
          </div>          
        </div>
      </div>
    </div>
    <div className={`mx-auto max-w-md flex flex-col items-center mb-16`}>
        <p className={`text-sm md:text-base text-center font-bold text-purple-800`}>The 4th Industrial Revolution</p>
        <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
        <p className={`text-center text-purple-800 mb-2`}>The Fourth Industrial Revolution is here. Year after year companies established in their digital transformation path outperform their peers. Digital channels in commerce are constantly bringing more revenue, already surpassing traditional channels in many industries. Let's talk and explore the opportunities together.</p>
        <Link to={`/web-development-quote`}><p className={`font-bold text-orange-500 text-center`}>Get in touch</p></Link>
    </div>
  </Layout>
)

export default DigitalTransformation
